import {useHistory} from "react-router-dom";
import {loadLocalAssets} from "utils/loader";

export function PostAddHeader({isShowToggleBtn, status, onToggleBtnPress, onSaveBtnPress, onSubmitBtnPress}) {
	const history = useHistory();

	const getStatus = () => {
		switch (status) {
			case "posted":
				return "Published";
			case "draft":
				return "Draft";
			case "scheduled":
				return "Scheduled";
			default:
				return "New";
		}
	};

	return (
		<>
			<header className="editor-header">
				<div className="editor-header-left gap-2">
					<button className="btn btn-editor" type="button" onClick={() => history.goBack()}>
						<span className="icon-ico-chev-left me-2" />
						Posts
					</button>

					<button className="btn btn-editor" disabled={true} type="button">
						{getStatus()}
					</button>
				</div>
				<div className="editor-header-right gap-2">
					<button
						className="btn btn-editor btn-editor-orange d-none d-sm-inline-block"
						type="button"
						onClick={onSaveBtnPress}>
						Save as draft
					</button>

					<button
						className="btn btn-orange r8 btn-editor d-none d-sm-inline-block"
						type="button"
						onClick={onSubmitBtnPress}>
						Publish
					</button>

					{isShowToggleBtn && (
						<button className="btn btn-editor" onClick={onToggleBtnPress}>
							<img alt="logo" className="img-fluid" src={loadLocalAssets("img/sidemenu.svg")} />
						</button>
					)}
				</div>
			</header>

			<div className="editor-mobile-action d-flex d-sm-none w-100 justify-content-end">
				<div>
					<button className="btn btn-editor btn-editor-orange" type="button" onClick={onSaveBtnPress}>
						Save as draft
					</button>

					<button className="btn btn-orange r8 btn-editor ms-2" type="button" onClick={onSubmitBtnPress}>
						Publish
					</button>
				</div>
			</div>
		</>
	);
}
