import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {useEffect} from "react";

import {ADD_TOGGLE} from "../constant";

export function TogglePlugin() {
	const [editor] = useLexicalComposerContext();

	useEffect(() => {
		const unregisterCommand = editor.registerCommand(
			ADD_TOGGLE,
			() => {
				editor.update(() => {
					editor.setEditable(true);
				});
			},
			1,
		);

		return () => unregisterCommand();
	}, [editor]);

	return null;
}
