import {useEffect} from "react";
import {$getRoot} from "lexical";
import {$generateHtmlFromNodes, $generateNodesFromDOM} from "@lexical/html";
import {ContentEditable} from "@lexical/react/LexicalContentEditable";
import {LexicalErrorBoundary} from "@lexical/react/LexicalErrorBoundary";
import {HistoryPlugin} from "@lexical/react/LexicalHistoryPlugin";
import {RichTextPlugin} from "@lexical/react/LexicalRichTextPlugin";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";

import {
	ParagraphPlugin,
	CalloutPlugin,
	FilePlugin,
	HeaderPlugin,
	HTMLPlugin,
	ImagePlugin,
	InstagramPlugin,
	QuotePlugin,
	TogglePlugin,
	TwitterPlugin,
	VideoPlugin,
	YoutubePlugin,
	FloatingMenuPlugin,
} from "./plugin";

function OnChangePlugin({onChange}) {
	const [editor] = useLexicalComposerContext();

	useEffect(() => {
		return editor.registerUpdateListener(({editorState}) => {
			editorState.read(() => {
				onChange({
					html: $generateHtmlFromNodes(editor),
				});
			});
		});
	}, [editor, onChange]);

	return null;
}

const InitialValuePlugin = ({initialValue}) => {
	const [editor] = useLexicalComposerContext();

	useEffect(() => {
		if (initialValue) {
			try {
				editor.update(() => {
					const root = $getRoot();

					const parser = new DOMParser();
					const dom = parser.parseFromString(initialValue, "text/html");
					const nodes = $generateNodesFromDOM(editor, dom);

					root.clear();
					root.append(...nodes);
				});
			} catch (error) {}
		}
	}, [editor, initialValue]);

	return null;
};

export function PostAddEditor({initialValue, onChange, onFocus}) {
	const [editor] = useLexicalComposerContext();

	useEffect(() => {
		editor.update(() => {
			const root = $getRoot();

			root.clear();
			editor.setEditable(false);
		});
	}, [editor]);

	const handleOnChange = ({html}) => {
		if (typeof onChange === "function") onChange(html);
	};

	const handleToggleFocus = newValue => () => {
		if (typeof onFocus === "function") onFocus(newValue);
	};

	return (
		<div className="editor-field-container">
			<div className="editor-field-inner">
				<RichTextPlugin
					ErrorBoundary={LexicalErrorBoundary}
					contentEditable={
						<ContentEditable
							className={"editor-field-input"}
							onBlur={handleToggleFocus(false)}
							onFocus={handleToggleFocus(true)}
						/>
					}
				/>

				<InitialValuePlugin initialValue={initialValue} />
				<HistoryPlugin />
				<ParagraphPlugin />
				<CalloutPlugin />
				<FilePlugin />
				<HeaderPlugin />
				<HTMLPlugin />
				<ImagePlugin />
				<InstagramPlugin />
				<QuotePlugin />
				<TogglePlugin />
				<TwitterPlugin />
				<VideoPlugin />
				<YoutubePlugin />
				<OnChangePlugin onChange={handleOnChange} />
				<FloatingMenuPlugin />
			</div>
		</div>
	);
}
