import {useEffect, useState, useRef} from "react";
import _ from "lodash";
import clsx from "clsx";
import dayjs from "dayjs";
import {BasicDropdown} from "components/Dropdown";
import {DropdownAsync} from "components/Dropdown/DropdownAsync";
import {TimePicker} from "components/TimePicker";
import CONFIG from "config/env";
import {useToast} from "hooks/useToast";
import {getStaffs} from "hooks/AdminHospital/Staff";
import {getArticlePostBySlug, getTagPost} from "hooks/SuperAdmin/Article";
import {useParams} from "react-router-dom";
import {loadLocalAssets} from "utils/loader";

import {POST_ACCESS_OPTS} from "./constant";

export function PostAddSideBar({
	isOpen,
	onClose,
	onDeleteBtnPress,
	handleBlur,
	handleChange,
	values,
	setFieldTouched,
	setFieldValue,
	errors,
	touched,
}) {
	const slugCheckTimeOut = useRef();

	const {id} = useParams();
	const {showToast} = useToast();

	const [tagDefaultOpts, setTagDefaultOpts] = useState([]);
	const [authorDefaultOpts, setAuthorDefaultOpts] = useState([]);

	useEffect(() => {
		const getTagDefaultOpts = async () => {
			const response = await getTagPost(null, {page: 1, limit: 10});
			const {rows} = response?.data ?? {};

			setTagDefaultOpts(rows.map(item => ({label: `${item.tag}`, value: item.id})));
		};

		if (tagDefaultOpts.length === 0) {
			getTagDefaultOpts();
		}
	}, [tagDefaultOpts]);

	useEffect(() => {
		const getAuthorDefaultOpts = async () => {
			const response = await getStaffs(null, {page: 1, limit: 10});
			const {rows} = response?.data ?? {};

			setAuthorDefaultOpts(rows.map(item => ({label: `${item.fullName}`, value: item.id})));
		};

		if (authorDefaultOpts.length === 0) {
			getAuthorDefaultOpts();
		}
	}, [authorDefaultOpts]);

	const fetchTagsData = async (search, callback) => {
		const response = await getTagPost(null, {page: 1, search, limit: 10});
		const {rows} = response?.data ?? {};

		callback(rows.map(item => ({label: `${item.tag}`, value: item.id})));
	};

	const fetchAuthorsData = async (search, callback) => {
		const response = await getStaffs(null, {page: 1, search, limit: 10});
		const {rows} = response?.data ?? {};

		callback(rows.map(item => ({label: `${item.fullName}`, value: item.id})));
	};

	const handleOnSlugChange = async event => {
		const newValue = event.target.value;
		const convertValue = newValue.replace(/ /g, "-");

		setFieldValue("input_slug", convertValue);
		clearTimeout(slugCheckTimeOut.current);

		slugCheckTimeOut.current = setTimeout(async () => {
			try {
				const res = await getArticlePostBySlug(convertValue);

				if (res) setFieldValue("slug", `${convertValue}-${dayjs().format("YYYYMMDDHHmmss")}`);
				else setFieldValue("slug", convertValue);
			} catch (error) {
				showToast("error", error.message, 3000);
			}
		}, 1000);
	};

	return (
		<div className="sidebar setting-sidebar" onClick={onClose(!isOpen)}>
			<div className="nav-box" onClick={e => e.stopPropagation()}>
				<div className="sidebar-header">
					<div className="sidebar-header-title">Post Settings</div>

					<button className="btn btn-editor" type="button" onClick={onClose(!isOpen)}>
						<img
							alt="sidemenu-active"
							className="img-fluid"
							src={loadLocalAssets("img/sidemenu-active.svg")}
						/>
					</button>
				</div>

				<div className="form gap-4 d-flex flex-column">
					<div>
						<label className="setting-label">Post URL</label>
						<input
							className={clsx("form-control", errors?.slug && touched?.slug && "form-control-error")}
							name="input-slug"
							placeholder={values.title_slug}
							type="text"
							value={values.input_slug}
							onBlur={handleBlur}
							onChange={handleOnSlugChange}
						/>
						<p className="form-information-message">
							{CONFIG.endpoint.APP_ENDPOINT}/{!_.isEmpty(values.slug) ? values.slug : values.title_slug}
						</p>
						{errors.slug && <p className="form-error-item-message">{errors.slug}</p>}
					</div>
					<div>
						<label className="setting-label">Publish Date</label>
						<div className="d-flex gap-3">
							<input
								className={clsx(
									"form-control",
									errors?.publishDate && touched?.publishDate && "form-control-error",
								)}
								min={new Date().toISOString().split("T")[0]}
								name="publishDate"
								placeholder=""
								type="date"
								value={values?.publishDate}
								onBlur={handleBlur}
								onChange={handleChange}
							/>
							<TimePicker
								className={errors.time ? "rc-time-picker-input-error" : ""}
								value={values?.time}
								onChange={fieldValue => {
									setFieldTouched(`time`, true);
									setFieldValue(`time`, fieldValue?.format("HH:mm"));
								}}
							/>
						</div>
						{(errors.publishDate || errors.time) && (
							<p className="form-error-item-message">{errors.publishDate || errors.time}</p>
						)}
					</div>
					<div>
						<label className="setting-label">Tags</label>
						<DropdownAsync
							defaultOptions={tagDefaultOpts}
							defaultValue={values.tagIds}
							fetchOptions={fetchTagsData}
							isCreatable={true}
							isError={errors.tagIds}
							isMulti={true}
							name="tagIds"
							onChange={fieldValue => {
								setFieldValue("tagIds", fieldValue);
							}}
						/>
						{errors.tagIds && <p className="form-error-item-message">{errors.tagIds}</p>}
					</div>
					<div>
						<label className="setting-label">Post Access</label>
						<BasicDropdown
							className={clsx("form-control", errors?.postAccess && "form-control-error")}
							data={POST_ACCESS_OPTS}
							defaultValue={values?.postAccess}
							name="postAccess"
							value={values?.postAccess}
							onSelect={fieldValue => {
								setFieldValue("postAccess", fieldValue);
							}}
						/>
						{errors.postAccess && <p className="form-error-item-message">{errors.postAccess}</p>}
					</div>
					<div>
						<label className="setting-label">Excerpt</label>
						<textarea
							className={clsx(
								"form-control",
								errors?.excerpt && touched?.excerpt && "form-control-error",
							)}
							name="excerpt"
							rows="4"
							spellCheck={false}
							value={values.excerpt}
							onBlur={handleBlur}
							onChange={handleChange}
						/>
						{errors.excerpt && <p className="form-error-item-message">{errors.excerpt}</p>}
					</div>
					<div>
						<label className="setting-label">Meta Title</label>
						<input
							className={clsx(
								"form-control",
								errors?.meta_title && touched?.meta_title && "form-control-error",
							)}
							name="meta_title"
							placeholder=""
							type="text"
							value={values.meta_title}
							onBlur={handleBlur}
							onChange={handleChange}
						/>
						{errors.meta_title && <p className="form-error-item-message">{errors.meta_title}</p>}
					</div>
					<div>
						<label className="setting-label">Meta Description</label>
						<textarea
							className={clsx(
								"form-control",
								errors?.meta_description && touched?.meta_description && "form-control-error",
							)}
							name="meta_description"
							rows="4"
							spellCheck={false}
							value={values.meta_description}
							onBlur={handleBlur}
							onChange={handleChange}
						/>
						{errors.meta_description && (
							<p className="form-error-item-message">{errors.meta_description}</p>
						)}
					</div>
					<div>
						<label className="setting-label">Authors</label>
						<DropdownAsync
							defaultOptions={authorDefaultOpts}
							defaultValue={values.authorIds}
							fetchOptions={fetchAuthorsData}
							isError={errors.authorIds}
							isMulti={true}
							name="authorIds"
							onChange={fieldValue => {
								setFieldValue("authorIds", fieldValue);
							}}
						/>
						{errors.authorIds && <p className="form-error-item-message">{errors.authorIds}</p>}
					</div>
					{id && (
						<div>
							<button
								className="btn btn-editor btn-editor-red"
								style={{position: "inherit"}}
								type="button"
								onClick={onDeleteBtnPress}>
								<span className="icon-ico-delete me-2" />
								Delete Post
							</button>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
