import {useState} from "react";
import {useQuery} from "react-query";
import axios from "services/axios";

export const QUERY = "post";

export async function getPosts(filter) {
	const {data} = await axios.get(`/article`, {params: {sortBy: "DESC", ...filter}});

	return data;
}

export function usePost(userId) {
	const [filter, filterPosts] = useState({
		page: 1,
		search: null,
		limit: 10,
	});
	const fallback = [];
	const {
		data = fallback,
		isLoading,
		isError,
		error,
	} = useQuery([QUERY, filter], async () => getPosts({...filter, userId}));

	return {data, isLoading, isError, error, filter, filterPosts};
}

export const fetchPostByID = postId => {
	return axios.get(`/article/${postId}`).then(res => res.data);
};

export function useDetailPost(postId) {
	return useQuery({
		queryKey: postId && ["post", postId],
		queryFn: key => fetchPostByID(postId),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		enabled: !!postId,
	});
}
