import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {$createParagraphNode, $getRoot} from "lexical";
import {useEffect} from "react";

import {ADD_PARAGRAPH} from "../constant";

export function ParagraphPlugin() {
	const [editor] = useLexicalComposerContext();

	useEffect(() => {
		const unregisterCommand = editor.registerCommand(
			ADD_PARAGRAPH,
			() => {
				editor.update(() => {
					editor.setEditable(true);

					const root = $getRoot();
					const paragraphNode = $createParagraphNode();

					root.append(paragraphNode);
					paragraphNode.select();
				});
			},
			1,
		);

		return () => unregisterCommand();
	}, [editor]);

	return null;
}
