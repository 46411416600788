import {useEffect} from "react";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {$getRoot} from "lexical";
import {$createQuoteNode} from "@lexical/rich-text";

import {ADD_QUOTE} from "../constant";

export function QuotePlugin() {
	const [editor] = useLexicalComposerContext();

	useEffect(() => {
		const unregisterCommand = editor.registerCommand(
			ADD_QUOTE,
			() => {
				editor.update(() => {
					editor.setEditable(true);

					const root = $getRoot();
					const quoteNode = $createQuoteNode();

					root.append(quoteNode);
					quoteNode.select();
				});
			},
			1,
		);

		return () => unregisterCommand();
	}, [editor]);

	return null;
}
