import clsx from "clsx";
import {useRef, useState} from "react";
import {Formik} from "formik";
import * as Yup from "yup";
import _ from "lodash";
import dayjs from "dayjs";
import {useSelector} from "react-redux";
import {isMobile} from "react-device-detect";
import {LexicalComposer} from "@lexical/react/LexicalComposer";
import {QuoteNode, HeadingNode} from "@lexical/rich-text";
import {useParams, useHistory} from "react-router-dom";
import {loadLocalAssets} from "utils/loader";
import {useToast} from "hooks/useToast";
import {useAddPost, useDeletePost, useDetailPost, useEditPost} from "hooks/Doctor/Post";
import {getArticlePostBySlug, getTagPost, postTagPost} from "hooks/SuperAdmin/Article";
import {convertErrorMessageFormat} from "utils/converter";
import {CircleLoader} from "components/Loader";
import {ModalConfirmation} from "components/Modal";
import {useUploadFile} from "hooks/Misc";
import {Toast} from "components/Toast";

import {PostAddHeader} from "./PostAddHeader";
import {PostAddSideBar} from "./PostAddSideBar";
import {PostAddEditor} from "./PostAddEditor";
import {PostAddEditorToolbar} from "./PostAddEditorToolbar";
import {INITIAL_VALUES, EDITOR_CONFIG} from "./constant";

const validationSchema = Yup.object().shape({
	tagIds: Yup.array().min(1, "Field wajib diisi!"),
	authorIds: Yup.array().min(1, "Field wajib diisi!"),
	postAccess: Yup.string().required("Field wajib diisi!"),
	excerpt: Yup.string().required("Field wajib diisi!"),
	meta_title: Yup.string().required("Field wajib diisi!"),
	meta_description: Yup.string().required("Field wajib diisi!"),
});

const editorConfig = {
	...EDITOR_CONFIG,
	nodes: [QuoteNode, HeadingNode],
	onError(error) {
		throw error;
	},
};

export function PostAdd() {
	const slugCheckTimeOut = useRef();
	const formikRef = useRef();

	const {id} = useParams();
	const history = useHistory();
	const {showToast} = useToast();

	const addMutation = useAddPost();
	const updateMutation = useEditPost();
	const uploadFileMutation = useUploadFile();

	const {data: detailPost, isLoading: loadingFetcing} = useDetailPost(id);
	const {mutate: deletePostById, isLoading: isDeletingData} = useDeletePost();

	const userProfile = useSelector(state => state.AuthReducer.profile);

	const [isSettingOpen, setIsSettingOpen] = useState(false);
	const [isToolbarOpen, setIsToolbarOpen] = useState(false);
	const [logoPreview, setLogoPreview] = useState(null);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const getDetailPostFormatedData = () => {
		if (!detailPost?.data) return {};

		const initialValueKey = Object.keys({...INITIAL_VALUES, input_slug: ""});
		const detailPostData = detailPost.data;
		const formatedPostData = {
			...detailPostData,
			input_slug: detailPostData?.slug ?? "",
			publishDate: detailPostData?.publishDate ? dayjs(detailPostData.publishDate).format("YYYY-MM-DD") : "",
			time: detailPostData?.time ? detailPostData.time.slice(0, -3) : "",
			tagIds: (detailPostData?.tags ?? []).map(item => ({label: `${item.tag}`, value: item.id})),
			authorIds: (detailPostData?.authors ?? []).map(item => ({
				label: `${item.firstName} ${item.lastName}`,
				value: item.id,
			})),
		};

		return initialValueKey.reduce((accu, item) => {
			if (formatedPostData[item]) {
				return {...accu, [item]: formatedPostData[item]};
			}

			return accu;
		}, INITIAL_VALUES);
	};

	const handleToggleSettingMenu = newMenuStatus => () => {
		setIsSettingOpen(newMenuStatus);
	};

	const handleToggleToolbarMenu = newMenuStatus => () => {
		setIsToolbarOpen(newMenuStatus);
	};

	const handleToggleDeleteModal = () => {
		setShowDeleteModal(true);
	};

	const handleSetThumbnail = e => {
		const file = e.target.files[0];
		const reader = new FileReader();

		if (!file) return;
		if (!file.type.includes("image")) {
			showToast("error", "Format foto tidak support, hanya menerima format .JPG .JPEG .PNG", 3000);

			return;
		}
		if (file.size > 5242880) {
			showToast("error", "thumbnail", "Ukuran file maksimal 5 ", 3000);

			return;
		}

		reader.onload = () => {
			setLogoPreview(reader.result);
		};
		reader.readAsDataURL(file);
		formikRef.current?.setFieldValue("thumbnail", file);
	};

	const handleOnTitleChange = async event => {
		const newValue = event.target.value;
		const currentSlug = formikRef.current?.values?.slug ?? "";
		const convertValue = newValue.replace(/ /g, "-");

		formikRef.current?.setFieldValue("title", newValue);

		if (_.isEmpty(currentSlug)) {
			clearTimeout(slugCheckTimeOut.current);

			slugCheckTimeOut.current = setTimeout(async () => {
				try {
					const res = await getArticlePostBySlug(convertValue);

					if (res) {
						formikRef.current?.setFieldValuesetFieldValue(
							"slug",
							`${convertValue}-${dayjs().format("YYYYMMDDHHmmss")}`,
						);
					} else {
						formikRef.current?.setFieldValuesetFieldValue("slug", convertValue);
					}
				} catch (error) {
					showToast("error", error.message, 3000);
				}
			}, 1000);
		}
	};

	const handleDeleteBanner = () => {
		formikRef.current?.setFieldValue("thumbnail", "");
		setLogoPreview(null);
	};

	const handleEditorChange = html => {
		formikRef.current?.setFieldValue("content", html);

		if (isMobile) {
			setIsToolbarOpen(false);
		}
	};

	const handleUploadThumbnail = values => {
		let formData = new FormData();

		formData.append("file", values.thumbnail);

		uploadFileMutation.mutate(formData, {
			onSuccess: result => {
				formikRef.current?.setFieldValue("thumbnail", result.data?.file ?? "");

				handleOnSubmit({...values, thumbnail: result.data?.file ?? ""});
			},
			onError: res => {
				showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				window.scrollTo(0, 0);
			},
		});
	};

	const handleOnSubmit = async values => {
		let currentTagIds = [...values.tagIds];
		const newTagIds = currentTagIds.filter(item => item.__isNew__);

		if (newTagIds.length > 0) {
			await Promise.all(newTagIds.map(item => postTagPost({tag: item.value})));

			const newTagIdsRes = await getTagPost(null, {page: 1, limit: 10});
			const newTagIdsResData = newTagIdsRes?.data?.rows ?? [];

			currentTagIds = currentTagIds.map(item => {
				if (!item?.__isNew__) return item;

				const currentTagIdsRes = newTagIdsResData.find(itm => itm.tag === item.value);

				return {label: item.label, value: currentTagIdsRes.id};
			});
		}

		if (typeof values.thumbnail === "object") {
			handleUploadThumbnail(values);

			return;
		}

		const isSchedule = dayjs(`${values.publishDate} ${values.time}`).isAfter();
		const payload = {
			title: !_.isEmpty(values.title) ? values.title : "Untitled",
			content: !_.isEmpty(values.content) ? values.content : "",
			excerpt: !_.isEmpty(values.excerpt) ? values.excerpt : "",
			thumbnail: !_.isEmpty(values.thumbnail) ? values.thumbnail : "",
			meta_title: !_.isEmpty(values.meta_title) ? values.meta_title : "",
			meta_description: !_.isEmpty(values.meta_description) ? values.meta_description : "",
			slug: !_.isEmpty(values.slug) ? values.slug : "",
			time: !_.isEmpty(values.time) ? values.time : dayjs().format("HH:mm"),
			publishDate: !_.isEmpty(values.publishDate) ? values.publishDate : dayjs().format("YYYY-MM-DD"),

			postAccess: values.postAccess,
			status: values.status === "posted" && isSchedule ? "scheduled" : values.status,
			tagIds: currentTagIds.map(item => item.value),
			authorIds: values.authorIds.map(item => item.value),

			userId: userProfile.id,
		};

		const onError = res => {
			showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
		};

		if (id) {
			updateMutation.mutate(
				{...payload, id},
				{
					onSuccess: () => {
						showToast("success", `Artikel berhasil diperbarui!`, 3000);
						history.push("/doctor/post/list");
					},
					onError,
				},
			);
		} else {
			addMutation.mutate(payload, {
				onSuccess: () => {
					showToast("success", `Artikel berhasil ditambahkan!`, 3000);
					history.push("/doctor/post/list");
				},
				onError,
			});
		}
	};

	const handleOnSave = status => () => {
		formikRef.current?.setFieldValue("status", status);

		if (status === "draft") {
			handleOnSubmit({
				...(formikRef.current?.values ?? {}),
				status,
			});

			return;
		}

		formikRef.current?.handleSubmit();
	};

	const handleOnDelete = () => {
		deletePostById(
			{id},
			{
				onSuccess: () => {
					showToast("success", `Artikel berhasil dihapus!`, 3000);
					history.push("/doctor/post/list");
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			},
		);
	};

	if (id && loadingFetcing) {
		return (
			<div className="min-vh-100 overflow-hidden">
				<CircleLoader />
			</div>
		);
	}

	return (
		<Formik
			initialValues={{...INITIAL_VALUES, ...getDetailPostFormatedData()}}
			innerRef={formikRef}
			validateOnBlur={false}
			validateOnChange={false}
			validationSchema={validationSchema}
			onSubmit={handleOnSubmit}>
			{formik => (
				<LexicalComposer initialConfig={editorConfig}>
					<div
						className={clsx(
							"main-wrapper editor-wrapper",
							isSettingOpen && "setting-opened",
							isToolbarOpen && "toolbar-opened",
						)}>
						<PostAddEditorToolbar isOpen={isToolbarOpen} onClose={handleToggleToolbarMenu} />

						<div className="page-content">
							<PostAddHeader
								isShowToggleBtn={!isSettingOpen}
								status={formik.values.status}
								onSaveBtnPress={handleOnSave("draft")}
								onSubmitBtnPress={handleOnSave("posted")}
								onToggleBtnPress={handleToggleSettingMenu(!isSettingOpen)}
							/>

							<div className="editor-main min-vh-100">
								<div className="editor-content">
									<input
										hidden
										accept="image/*"
										id="upload-banner"
										type="file"
										onChange={handleSetThumbnail}
										onClick={() => formik.setTouched({...formik.touched, thumbnail: true})}
									/>

									{logoPreview ||
									(typeof formik.values.thumbnail === "string" &&
										!_.isEmpty(formik.values.thumbnail)) ? (
										<div className="editor-banner mb-4">
											<img
												alt="logo"
												className="img-fluid"
												src={logoPreview || formik.values.thumbnail}
											/>

											<button
												className="editor-banner-btn-remover btn"
												type="button"
												onClick={handleDeleteBanner}>
												<span className="icon-ico-delete" />
											</button>
										</div>
									) : (
										<label
											className="editor-banner-btn d-flex align-items-center mb-4"
											htmlFor="upload-banner">
											<div className="editor-banner-btn-icon me-3">
												<img
													alt="logo"
													className="img-fluid"
													src={loadLocalAssets("img/ri_unsplash-fill.svg")}
												/>
											</div>
											<div className="editor-banner-btn-text">Add feature image</div>
										</label>
									)}

									<input
										className="form-control editor-banner-title-field"
										name="title"
										placeholder="Post Title"
										type="text"
										value={formik.values.title}
										onBlur={formik.handleBlur}
										onChange={handleOnTitleChange}
									/>

									<div className="w-100">
										<PostAddEditor
											initialValue={getDetailPostFormatedData()?.content}
											onChange={handleEditorChange}
										/>

										{!isToolbarOpen && (
											<button
												className="btn btn-editor btn-editor-toolbar-trigger mt-4"
												type="button"
												onClick={handleToggleToolbarMenu(!isToolbarOpen)}>
												<img
													alt="logo"
													className="img-fluid"
													src={loadLocalAssets("img/add.svg")}
												/>
											</button>
										)}
									</div>
								</div>
							</div>

							<Toast />
						</div>

						<PostAddSideBar
							{...formik}
							isOpen={isSettingOpen}
							onClose={handleToggleSettingMenu}
							onDeleteBtnPress={handleToggleDeleteModal}
						/>
					</div>

					<ModalConfirmation
						approveLabel="Ya"
						description={`Apakah Anda yakin ingin menghapus artikel?`}
						loading={isDeletingData}
						rejectLabel="Batal"
						visible={showDeleteModal}
						onApprove={handleOnDelete}
						onClose={() => setShowDeleteModal(false)}
						onReject={() => setShowDeleteModal(false)}
					/>
				</LexicalComposer>
			)}
		</Formik>
	);
}
